const mainCourse = {
  subCategories: [
    "Plain Roast / Dosa",
    "Mushroom Roast",
    "Egg Roast",
    "Paneer Roast",
    "Butter Roast",
    "Ghee Roast",
    "Uttapam",
    "Pulao",
    "Chahan Rice (₹ 20 extra for spicy)",
    "Chahan Noodles (₹ 20 extra for spicy)",
    "Gravies",
    "Breads",
  ],
  items: [
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PlainRoast.jpg",
      foodName: "Plain Roast",
      foodPrice: "59",
      type: "mainCourse",
      subType: "Plain Roast / Dosa",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/KalDosa.jpg",
      foodName: "Kal Dosa (1 set)",
      foodPrice: "58",
      type: "mainCourse",
      subType: "Plain Roast / Dosa",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomRoast.jpg",
      foodName: "Ginza Mushroom Roast",
      foodPrice: "90",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomGarlicRoast.jpg",
      foodName: "Ginza Mushroom Garlic Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomRoast.jpg",
      foodName: "Ginza Mushroom Peri Peri Roast",
      foodPrice: "90",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomRoast.jpg",
      foodName: "Ginza Mushroom Podi Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomOnionRoast.jpg",
      foodName: "Ginza Mushroom Onion Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MuhsroomSchezwanRoast.jpg",
      foodName: "Ginza Mushroom Schezwan Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomCorianderRoast.jpg",
      foodName: "Ginza Mushroom Dhaniya Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomTomatoRoast.jpg",
      foodName: "Ginza Mushroom Tomato Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomCheesePeasRoast.jpg",
      foodName: "Ginza Mushroom Peas & Cheese Roast",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomPeasRoast.jpg",
      foodName: "Ginza Mushroom Peas Roast",
      foodPrice: "119",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomGobiRoast.jpg",
      foodName: "Ginza Mushroom Gobi Masala Roast",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomEggRoast.jpg",
      foodName: "Ginza Mushroom Egg Roast",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerMushroomRoast.jpg",
      foodName: "Ginza Mushroom Paneer Roast",
      foodPrice: "149",
      type: "mainCourse",
      subType: "Mushroom Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggRoast.jpg",
      foodName: "Ginza Egg Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggMasalaRoast.jpg",
      foodName: "Ginza Egg Masala Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerEggRoast.jpg",
      foodName: "Ginza Egg Paneer Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomEggRoast.jpg",
      foodName: "Ginza Egg Mushroom Roast",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggGreenPeasRoast.jpg",
      foodName: "Ginza Egg Green Peas Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggVegMasala.jpg",
      foodName: "Ginza Egg Veg Masala Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Ginza Egg Gobi Masala Roast",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggRoast.jpg",
      foodName: "Ginza Egg Podi Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Ginza Egg Schezwan Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggRoast.jpg",
      foodName: "Ginza Egg Peri Peri Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggBhurjiRoast.jpg",
      foodName: "Ginza Egg Bhurji Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggGarlicRoast.jpg",
      foodName: "Ginza Egg Garlic Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggTomatoRoast.jpg",
      foodName: "Ginza Egg Tomato Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Egg Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerRoast.jpg",
      foodName: "Ginza Paneer Roast",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerGarlicRoast.jpg",
      foodName: "Ginza Paneer Garlic Roast",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerTomatoOnionRoast.jpg",
      foodName: "Ginza Paneer Tomato & Onion Roast",
      foodPrice: "149",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerRoast.jpg",
      foodName: "Ginza Paneer Podi Roast",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerOnionRoast.jpg",
      foodName: "Ginza Paneer Onion Roast",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerRoast.jpg",
      foodName: "Ginza Paneer Schezwan Roast",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerDhaniyaRoast.jpg",
      foodName: "Ginza Paneer Dhaniya Roast",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerRoast.jpg",
      foodName: "Ginza Paneer Masala Roast",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerPeasRoast.jpg",
      foodName: "Ginza Paneer Peas Roast",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerGobiMasalaRoast.jpg",
      foodName: "Ginza Paneer Gobi Masala Roast",
      foodPrice: "159",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerRoast.jpg",
      foodName: "Ginza Paneer Peri Peri Roast",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerRoast.jpg",
      foodName: "Ginza Paneer Cheese Roast",
      foodPrice: "149",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerMushroomRoast.jpg",
      foodName: "Ginza Paneer Mushroom Roast",
      foodPrice: "149",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl:
        "images/FoodItems/mainCourse/roast/PaneerMushroomGobiMasalaRoast.jpg",
      foodName: "Ginza Paneer Mushroom Gobi Masala Roast",
      foodPrice: "169",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerPeasCheeseRoast.jpg",
      foodName: "Ginza Paneer Peas & Cheese Roast",
      foodPrice: "159",
      type: "mainCourse",
      subType: "Paneer Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/ButterRoast.jpg",
      foodName: "Butter Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/GarlicRoast.jpg",
      foodName: "Butter Garlic Roast",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/EggRoast.jpg",
      foodName: "Butter Egg Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/ButterRoast.jpg",
      foodName: "Butter Podi Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/PaneerRoast.jpg",
      foodName: "Butter Paneer Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/OnionRoast.jpg",
      foodName: "Butter Onion Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MasalaRoast.jpg",
      foodName: "Butter Masala Roast",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/DhaniyaRoast.jpg",
      foodName: "Butter Dhaniya Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/MushroomRoast.jpg",
      foodName: "Butter Mushroom Roast",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/ChickenDosa.jpg",
      foodName: "Butter Kantara Chicken Roast",
      foodPrice: "159",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/ChickenKantaraLegRoast.jpg",
      foodName: "Butter Kantara Chicken Leg Roast",
      foodPrice: "169",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/ButterGobiRoast.jpg",
      foodName: "Butter Gobi Roast",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Butter Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/GheeRoast.JPG",
      foodName: "Ghee Roast",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/GheeVegRoast.jpg",
      foodName: "Ghee Veg Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/GheeSchezwanRoast.jpg",
      foodName: "Ghee Schezwan Roast",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/GheeRoast.JPG",
      foodName: "Ghee Podi Roast",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/OnionRoast.jpg",
      foodName: "Ghee Onion Roast",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/DhaniyaRoast.jpg",
      foodName: "Ghee Dhaniya Roast",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/GheeMushroomRoast.jpg",
      foodName: "Ghee Mushroom Roast",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/TomatoRoast.jpg",
      foodName: "Ghee Tomato Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/GreenPeasRoast.jpg",
      foodName: "Ghee Green Peas Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/roast/GobiMasalaRoast.jpg",
      foodName: "Ghee Gobi Masala Roast",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Ghee Roast",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/Uttapam.jpg",
      foodName: "Ginza Plain Uttapam",
      foodPrice: "49",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/VegUttapam.jpg",
      foodName: "Ginza Veg Masala Uttapam",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Ginza Veg Pizza Uttapam",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/GobiUttapam.jpg",
      foodName: "Ginza Gobi Masala Uttapam",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/CorianderUttapam.jpg",
      foodName: "Ginza Dhaniya Uttapam",
      foodPrice: "59",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/PeasUttapam.jpg",
      foodName: "Ginza Peas Uttapam",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Ginza Benne Uttapam",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/TomatoUttapam.jpg",
      foodName: "Ginza Tomato Uttapam",
      foodPrice: "59",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Ginza Masala Podi Uttapam",
      foodPrice: "59",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/ChocolateUttapam.jpg",
      foodName: "Ginza Chocolate Uttapam",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/ChickenUttapam.jpg",
      foodName: "Ginza Chicken Masala Uttapam",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/uttapam/PaneerUttapam.jpg",
      foodName: "Ginza Paneer Uttapam",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Ginza Aloo Uttapam",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Uttapam",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/rice/SteamRice.jpg",
      foodName: "Steam Rice",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Pulao",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/rice/JeeraRice.jpg",
      foodName: "Jeera Rice",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Pulao",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/rice/GheeRice.jpg",
      foodName: "Ghee Rice",
      foodPrice: "119",
      type: "mainCourse",
      subType: "Pulao",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/rice/VegPulao.jpg",
      foodName: "Veg Pulao",
      foodPrice: "119",
      type: "mainCourse",
      subType: "Pulao",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Pulao",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Pulao",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Pulao",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Pulao",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Pulao",
      foodPrice: "159",
      type: "mainCourse",
      subType: "Pulao",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Veg Rice",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Chahan Rice (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Rice",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Chahan Rice (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Rice",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Chahan Rice (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Egg Rice",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Chahan Rice (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mix Veg Rice",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Chahan Rice (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Rice",
      foodPrice: "149",
      type: "mainCourse",
      subType: "Chahan Rice (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Rice",
      foodPrice: "169",
      type: "mainCourse",
      subType: "Chahan Rice (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Cashewnut Fried Rice",
      foodPrice: "149",
      type: "mainCourse",
      subType: "Chahan Rice (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Veg Noodles",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Chahan Noodles (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Noodles",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Chahan Noodles (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Noodles",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Chahan Noodles (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Egg Noodles",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Chahan Noodles (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mix Veg Noodles",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Chahan Noodles (₹ 20 extra for spicy)",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Noodles",
      foodPrice: "149",
      type: "mainCourse",
      subType: "Chahan Noodles (₹ 20 extra for spicy)",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/noodles/Chicken_Noodles.JPG",
      foodName: "Chicken Noodles",
      foodPrice: "169",
      type: "mainCourse",
      subType: "Chahan Noodles (₹ 20 extra for spicy)",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/gravies/DalFry.jpg",
      foodName: "Dal Fry",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/gravies/DalTadka.jpg",
      foodName: "Dal Tadka",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Gravy",
      foodPrice: "119",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Green Peas Gravy",
      foodPrice: "119",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Channa Gravy",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Aloo Gobi Gravy",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Baby Corn Gravy",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Gravy",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Kadai Mushroom Gravy",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/gravies/EggMasala.jpg",
      foodName: "Egg Masala",
      foodPrice: "109",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/gravies/EggBhurji.jpg",
      foodName: "Egg Bhurji",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Egg Chettinadu",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Egg Kalakki",
      foodPrice: "19",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Egg Half Boiled",
      foodPrice: "19",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/gravies/Omelette.jpg",
      foodName: "Omelette",
      foodPrice: "19",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Bhurji",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Butter Paneer",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/gravies/PaneerGravy.jpg",
      foodName: "Kadai Paneer",
      foodPrice: "139",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Malai Kofta",
      foodPrice: "169",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/gravies/ChickenMasala.jpg",
      foodName: "Chicken Masala",
      foodPrice: "169",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Chettinadu",
      foodPrice: "169",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Pallipalayam",
      foodPrice: "199",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/gravies/ButterChicken.jpg",
      foodName: "Butter Chicken",
      foodPrice: "189",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Kadai Chicken",
      foodPrice: "199",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Malai Kofta",
      foodPrice: "199",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Pepper Chicken Masala",
      foodPrice: "179",
      type: "mainCourse",
      subType: "Gravies",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/Phulka.jpg",
      foodName: "Plain Phulka (2)",
      foodPrice: "38",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/Phulka.jpg",
      foodName: "Butter Phulka (2)",
      foodPrice: "58",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/Phulka.jpg",
      foodName: "Ghee Phulka (2)",
      foodPrice: "58",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Plain Kadak Roti",
      foodPrice: "39",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Butter Kadak Roti",
      foodPrice: "49",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Plain Kulcha",
      foodPrice: "49",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Butter Kulcha",
      foodPrice: "59",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Garlic Kulcha",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Aloo Kulcha",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Veg Kulcha",
      foodPrice: "69",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Kulcha",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/CholaPoori.JPG",
      foodName: "Chola Puri (with Gravy)",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Garlic Paratha",
      foodPrice: "79",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/AlooParatha.jpg",
      foodName: "Aloo Paratha",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/VegParatha.jpg",
      foodName: "Veg Paratha",
      foodPrice: "89",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/PaneerParatha.jpg",
      foodName: "Paneer Paratha",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/GarlicPaneerParatha.jpg",
      foodName: "Garlic Paneer Paratha",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl: "images/FoodItems/mainCourse/breads/GobiParatha.jpg",
      foodName: "Gobi Paratha",
      foodPrice: "99",
      type: "mainCourse",
      subType: "Breads",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mix 2 Paratha ( Your Choice )",
      foodPrice: "129",
      type: "mainCourse",
      subType: "Breads",
    },
  ],
};

export default mainCourse;
