import React from "react";
import { Box, Button, Card, Image, Text } from "@chakra-ui/react";

export default function MenuCard(props) {
  return (
    <Card.Root
      className="card"
      minW={{ base: "100%", md: "400px" }}
      maxH="200px"
      flexDirection="row"
      alignItems="center"
      overflow="hidden"
      maxW="sm"
    >
      <Image
        width={200}
        height={200}
        fit="fill"
        src={props?.imgUrl}
        alt="Item Image"
      />
      <Box>
        <Card.Body paddingBlock="10px">
          <Card.Title>
            <Text
              fontFamily="Poppins Medium"
              fontSize="14px"
              className="foodName"
            >
              {props.foodName}
            </Text>
          </Card.Title>
        </Card.Body>
        <Card.Footer>
          <Text
            className="sec-color"
            textStyle="2xl"
            fontWeight="medium"
            letterSpacing="tight"
            mt="2"
            fontFamily="Poppins Bold"
          >
            <Button> ₹ {props.foodPrice}</Button>
          </Text>
        </Card.Footer>
      </Box>
    </Card.Root>
  );
}
