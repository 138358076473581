import { Box, Flex, Image, Text, Icon } from "@chakra-ui/react";
import React from "react";
import Marquee from "react-fast-marquee";
import { BiSolidOffer } from "react-icons/bi";
import fullLogo from "./../assets/images/header-full-logo.png";
import { RiSeparator } from "react-icons/ri";

export default function Header() {
  return (
    <>
      <Flex
        w="100%"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        className="header"
        gap="20px"
      >
        <Image maxHeight={90} src={fullLogo} alt="header-logo"></Image>
      </Flex>
      <Box className="offer-slider">
        <Marquee speed="90">
          <Flex
            className="offer-text"
            justifyContent="center"
            alignItems="center"
          >
            <Icon fontSize="2xl" color="#ff0800" mr="5px">
              <BiSolidOffer width={200} height={200} />
            </Icon>
            <Text
              fontFamily="BubblegumSans-Regular"
              fontSize="20px"
              color="#ff0800"
            >
              Enjoy a complimentary dish on order above ₹ 199
            </Text>
            <Icon fontSize="2xl" color="#ff0800" ml="5px">
              <BiSolidOffer width={200} height={200} />
            </Icon>
          </Flex>
          <Flex justifyContent="center" alignItems="center">
            <Text
              fontFamily="BubblegumSans-Regular"
              fontSize="20px"
              mr="20px"
              color="#5e1914"
            >
              No Ajinomoto
            </Text>
            <Icon fontSize="2xl" mr="20px">
              <RiSeparator width={200} height={200} color="#5e1914" />
            </Icon>
          </Flex>
          <Flex justifyContent="center" alignItems="center">
            <Text
              fontFamily="BubblegumSans-Regular"
              fontSize="20px"
              mr="20px"
              color="#5e1914"
            >
              No Palmolein Oil
            </Text>
            <Icon fontSize="2xl" mr="20px">
              <RiSeparator width={200} height={200} color="#5e1914" />
            </Icon>
          </Flex>
          <Flex justifyContent="center" alignItems="center">
            <Text
              fontFamily="BubblegumSans-Regular"
              fontSize="20px"
              mr="20px"
              color="#5e1914"
            >
              No Added Colour
            </Text>
            <Icon fontSize="2xl" mr="20px">
              <RiSeparator width={200} height={200} color="#5e1914" />
            </Icon>
          </Flex>
          <Flex justifyContent="center" alignItems="center">
            <Text
              fontFamily="BubblegumSans-Regular"
              fontSize="20px"
              mr="50px"
              color="#5e1914"
            >
              Party Hall Available - BOOK NOW!
            </Text>
          </Flex>
        </Marquee>
      </Box>
    </>
  );
}
