const starters = {
  subCategories: ["Pastries", "Mousse Cake", "Sticks"],
  items: [
    {
      imgUrl: "images/FoodItems/desserts/BlackForest.jpg",
      foodName: "Black Forest Pastry",
      foodPrice: "199",
      type: "desserts",
      subType: "Pastries",
    },
    {
      imgUrl: "images/FoodItems/desserts/RedVelvet.jpg",
      foodName: "Red Velvet Pastry",
      foodPrice: "199",
      type: "desserts",
      subType: "Pastries",
    },
    {
      imgUrl: "images/FoodItems/desserts/BelgiumChoco.jpg",
      foodName: "Belgium Chocolate Pastry",
      foodPrice: "199",
      type: "desserts",
      subType: "Pastries",
    },
    {
      imgUrl: "images/FoodItems/desserts/WhiteForest.jpg",
      foodName: "White Forest Pastry",
      foodPrice: "199",
      type: "desserts",
      subType: "Pastries",
    },
    {
      imgUrl: "images/FoodItems/desserts/CaramelCake.jpg",
      foodName: "Caramel Pastry",
      foodPrice: "199",
      type: "desserts",
      subType: "Pastries",
    },
    {
      imgUrl: "images/FoodItems/desserts/RosePetal.jpg",
      foodName: "Rose Petal Pastry",
      foodPrice: "199",
      type: "desserts",
      subType: "Pastries",
    },
    {
      imgUrl: "images/FoodItems/desserts/ChocolateMousseCake.jpg",
      foodName: "Chocolate Mousse Cake",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Mousse Cake",
    },
    {
      imgUrl: "images/FoodItems/desserts/OreoChocolateMousseCake.jpg",
      foodName: "Oreo Chocolate Mousse Cake",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Mousse Cake",
    },
    {
      imgUrl: "images/FoodItems/desserts/BiscoffChocolateMousseCake.jpg",
      foodName: "Bischoff Chocolate Mousse Cake",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Mousse Cake",
    },
    {
      imgUrl: "images/FoodItems/desserts/TripleChocoMousseCake.jpg",
      foodName: "Tripple Chocolate Mousse Cake",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Mousse Cake",
    },
    {
      imgUrl: "images/FoodItems/desserts/NutsChocloateMousseCake.jpg",
      foodName: "Nuts Chocolate Mousse Cake",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Mousse Cake",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Marble Strawberry Sticks",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Sticks",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chocolate Strawberry Sticks",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Sticks",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Dark Fantasy Strawberry Sticks",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Sticks",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Dark Fantasy Banana Sticks",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Sticks",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chocolate Banana Sticks",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Sticks",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Marble Banana Sticks",
      foodPrice: "TBD",
      type: "desserts",
      subType: "Sticks",
    },
  ],
};

export default starters;
