const starters = {
  subCategories: [
    "Fries",
    "Bites - Veg",
    "Bites - Non Veg",
    "Momos - Veg",
    "Momos - Non Veg",
    "Soup",
    "65",
    "Cheese Balls",
    "Rolls",
    "Manchurian",
    "Lollipop",
    "Fry Items",
    "Pepper Fry Items",
  ],
  items: [
    {
      imgUrl: "images/FoodItems/starters/CrinkleFries.jpg",
      foodName: "Crinkle Fries",
      foodPrice: "90",
      type: "starters",
      subType: "Fries",
    },
    {
      imgUrl: "images/FoodItems/starters/PeriPeriCrinkleFries.jpg",
      foodName: "Peri Peri Crinkle Fries",
      foodPrice: "99",
      type: "starters",
      subType: "Fries",
    },
    {
      imgUrl: "images/FoodItems/starters/CheeseSamosa.jpg",
      foodName: "Cheese Samosa (2 Pcs)",
      foodPrice: "59",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/AlooTikki.jpg",
      foodName: "Aloo Tikki (2 Pcs)",
      foodPrice: "59",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/SpiralPotato.jpg",
      foodName: "Spiral Potato",
      foodPrice: "90",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/SpringPotato.jpg",
      foodName: "Spring Potato",
      foodPrice: "90",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/OnionRings.jpg",
      foodName: "Onion Rings",
      foodPrice: "90",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/ChilliGarlicPotatoPoppers.jpg",
      foodName: "Chilli Garlic Potato Poppers",
      foodPrice: "69",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/CheesyCornTriangles.jpg",
      foodName: "Cheesy Corn Triangles",
      foodPrice: "90",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/ChessyPizzaFingers.jpg",
      foodName: "Cheesy Pizza Fingers",
      foodPrice: "90",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/JalapenocheesyPops.jpg",
      foodName: "Jalapeno Cheesy Pops",
      foodPrice: "90",
      type: "starters",
      subType: "Bites - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/ChickenPopcorn.jpg",
      foodName: "Chicken Popcorn",
      foodPrice: "90",
      type: "starters",
      subType: "Bites - Non Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/Chicken_Nuggets.jpg",
      foodName: "Crunchy Chicken Nuggets",
      foodPrice: "90",
      type: "starters",
      subType: "Bites - Non Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/CrispyChickenFries.jpg",
      foodName: "Crispy Chicken Fries",
      foodPrice: "99",
      type: "starters",
      subType: "Bites - Non Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/ChickenWings.jpg",
      foodName: "Chicken Wings",
      foodPrice: "149",
      type: "starters",
      subType: "Bites - Non Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/VegMomo.jpg",
      foodName: "Veg Momos",
      foodPrice: "90",
      type: "starters",
      subType: "Momos - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/PaneerMomos.jpg",
      foodName: "Paneer Momos",
      foodPrice: "99",
      type: "starters",
      subType: "Momos - Veg",
    },
    {
      imgUrl: "images/FoodItems/starters/ChickenMomos.jpg",
      foodName: "Chicken Momos",
      foodPrice: "99",
      type: "starters",
      subType: "Momos - Non Veg",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Tikka Momos",
      foodPrice: "119",
      type: "starters",
      subType: "Momos - Non Veg",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Tomato Soup",
      foodPrice: "79",
      type: "starters",
      subType: "Soup",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Veg Soup",
      foodPrice: "89",
      type: "starters",
      subType: "Soup",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Veg Clear Soup",
      foodPrice: "69",
      type: "starters",
      subType: "Soup",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Soup",
      foodPrice: "99",
      type: "starters",
      subType: "Soup",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Hot & Sour Veg Soup",
      foodPrice: "89",
      type: "starters",
      subType: "Soup",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Soup",
      foodPrice: "119",
      type: "starters",
      subType: "Soup",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Sweetcorn Chicken Soup",
      foodPrice: "129",
      type: "starters",
      subType: "Soup",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Creamy Chicken Soup",
      foodPrice: "149",
      type: "starters",
      subType: "Soup",
    },
    {
      imgUrl: "images/FoodItems/starters/Gobi65.jpg",
      foodName: "Gobi 65",
      foodPrice: "99",
      type: "starters",
      subType: "65",
    },
    {
      imgUrl: "images/FoodItems/starters/Mushroom65.jpg",
      foodName: "Mushroom 65",
      foodPrice: "99",
      type: "starters",
      subType: "65",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Baby Corn 65",
      foodPrice: "99",
      type: "starters",
      subType: "65",
    },
    {
      imgUrl: "images/FoodItems/starters/Paneer65.jpg",
      foodName: "Paneer 65",
      foodPrice: "129",
      type: "starters",
      subType: "65",
    },
    {
      imgUrl: "images/FoodItems/starters/Chicken65.jpg",
      foodName: "Chicken 65",
      foodPrice: "149",
      type: "starters",
      subType: "65",
    },
    {
      imgUrl: "images/FoodItems/starters/VegCheeseBalls.jpg",
      foodName: "Veg Cheese Balls",
      foodPrice: "99",
      type: "starters",
      subType: "Cheese Balls",
    },
    {
      imgUrl: "images/FoodItems/starters/AlooCheeseBalls.jpg",
      foodName: "Aloo Cheese Balls",
      foodPrice: "99",
      type: "starters",
      subType: "Cheese Balls",
    },
    {
      imgUrl: "images/FoodItems/starters/PaneerCheeseBalls.jpg",
      foodName: "Paneer Cheese Balls",
      foodPrice: "129",
      type: "starters",
      subType: "Cheese Balls",
    },
    {
      imgUrl: "images/FoodItems/starters/ChickenCheeseBalls.jpg",
      foodName: "Chicken Cheese Balls",
      foodPrice: "149",
      type: "starters",
      subType: "Cheese Balls",
    },
    {
      imgUrl: "images/FoodItems/starters/VegRoll.jpg",
      foodName: "Veg Roll",
      foodPrice: "99",
      type: "starters",
      subType: "Rolls",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Roll",
      foodPrice: "99",
      type: "starters",
      subType: "Rolls",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Roll",
      foodPrice: "119",
      type: "starters",
      subType: "Rolls",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Egg Roll",
      foodPrice: "119",
      type: "starters",
      subType: "Rolls",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Roll",
      foodPrice: "139",
      type: "starters",
      subType: "Rolls",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Roll",
      foodPrice: "149",
      type: "starters",
      subType: "Rolls",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Veg Manchurian",
      foodPrice: "129",
      type: "starters",
      subType: "Manchurian",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Manchurian",
      foodPrice: "119",
      type: "starters",
      subType: "Manchurian",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Manchurian",
      foodPrice: "129",
      type: "starters",
      subType: "Manchurian",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Baby Corn Manchurian",
      foodPrice: "119",
      type: "starters",
      subType: "Manchurian",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Manchurian",
      foodPrice: "139",
      type: "starters",
      subType: "Manchurian",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Manchurian",
      foodPrice: "159",
      type: "starters",
      subType: "Manchurian",
    },
    {
      imgUrl:"images/FoodItems/starters/VegLollipop.jpg",
      foodName: "Veg Lollipop",
      foodPrice: "129",
      type: "starters",
      subType: "Lollipop",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Lollipop",
      foodPrice: "119",
      type: "starters",
      subType: "Lollipop",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Lollipop",
      foodPrice: "129",
      type: "starters",
      subType: "Lollipop",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Lollipop",
      foodPrice: "139",
      type: "starters",
      subType: "Lollipop",
    },
    {
      imgUrl: "images/FoodItems/starters/ChickenLollipop.jpg",
      foodName: "Chicken Lollipop",
      foodPrice: "159",
      type: "starters",
      subType: "Lollipop",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Aloo Jeera Fry",
      foodPrice: "90",
      type: "starters",
      subType: "Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Fry",
      foodPrice: "99",
      type: "starters",
      subType: "Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Aloo Gobi Fry",
      foodPrice: "119",
      type: "starters",
      subType: "Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Fry",
      foodPrice: "129",
      type: "starters",
      subType: "Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Baby Corn Fry",
      foodPrice: "119",
      type: "starters",
      subType: "Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Fry",
      foodPrice: "139",
      type: "starters",
      subType: "Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Fry",
      foodPrice: "149",
      type: "starters",
      subType: "Fry Items",
    },
    {
      imgUrl: "images/FoodItems/starters/BhindiFry.jpg",
      foodName: "Bhindi Fry",
      foodPrice: "90",
      type: "starters",
      subType: "Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Aloo Jeera Pepper Fry",
      foodPrice: "99",
      type: "starters",
      subType: "Pepper Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Gobi Pepper Fry",
      foodPrice: "119",
      type: "starters",
      subType: "Pepper Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Aloo Gobi Pepper Fry",
      foodPrice: "129",
      type: "starters",
      subType: "Pepper Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mushroom Pepper Fry",
      foodPrice: "129",
      type: "starters",
      subType: "Pepper Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Baby Corn Pepper Fry",
      foodPrice: "129",
      type: "starters",
      subType: "Pepper Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Paneer Pepper Fry",
      foodPrice: "149",
      type: "starters",
      subType: "Pepper Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chicken Pepper Fry",
      foodPrice: "169",
      type: "starters",
      subType: "Pepper Fry Items",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Bhindi Pepper Fry",
      foodPrice: "90",
      type: "starters",
      subType: "Pepper Fry Items",
    },
  ],
};

export default starters;
