import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Header from "./components/Header";
import Menu from "./pages/Menu";

function App() {
  return (
    <div>
      <Router>
        <Header></Header>
        <Routes>
          <Route path={"/menu"} element={<Menu />}></Route>
          <Route path="*" element={<Navigate to="/menu" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
