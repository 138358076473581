import React, { useEffect, useState } from "react";
import MenuCard from "../components/MenuCard";
import { Flex, Tabs, Stack, Text, Box } from "@chakra-ui/react";
import starters from "../data/starters";
import mainCourse from "../data/mainCourse";
import drinks from "../data/drinks";
import desserts from "../data/desserts";
import { FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";

export default function Menu() {
  const [searchValue, setSearchValue] = useState("");
  const [filteredMenu, setFilteredMenu] = useState([]);
  const [selectedTab, selectSelectedTab] = useState("starters");

  const search = (event) => {
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const emptySearch = (event) => {
    setSearchValue("");
  };

  useEffect(() => {
    let menu = [
      ...starters.items,
      ...mainCourse.items,
      ...drinks.items,
      ...desserts.items,
    ];
    setFilteredMenu(
      menu.filter((data) =>
        data.foodName.toLowerCase().includes(searchValue.trim().toLowerCase())
      )
    );
  }, [searchValue]);

  return (
    <>
      {/* Search Bar */}
      <Box paddingBottom="30px">
        <Text className="menu-list-heading" mt="10px">
          MENU
        </Text>
        <Box
          className="search-box"
          marginInline={{ base: "12px", md: "50px" }}
          mt="10px"
          mb="30px"
        >
          <FaSearch className="search-icon" />
          <input
            type="search"
            placeholder="Search for dishes..."
            value={searchValue}
            onChange={search}
          />
          {searchValue && (
            <ImCross className="cross-icon" onClick={emptySearch} />
          )}
        </Box>
        {!searchValue && (
          <Tabs.Root
            mt="20px"
            value={selectedTab}
            variant="plain"
            fitted
            fontFamily="Poppins Bold"
            paddingInline={{ base: "12px", md: "50px" }}
            onFocusChange={(val) => selectSelectedTab(val.focusedValue)}
          >
            <Tabs.List>
              <Tabs.Trigger
                value="starters"
                marginRight="10px"
                backgroundColor={
                  selectedTab === "starters" ? "black" : "#fceda7"
                }
                color={selectedTab === "starters" ? "white" : "black"}
                fontFamily="BubblegumSans-Regular"
                fontSize="16px"
                fontWeight="600"
                minWidth="80px"
              >
                STARTERS
              </Tabs.Trigger>
              <Tabs.Trigger
                value="mainCourse"
                marginRight="10px"
                backgroundColor={
                  selectedTab === "mainCourse" ? "black" : "#fceda7"
                }
                color={selectedTab === "mainCourse" ? "white" : "black"}
                fontFamily="BubblegumSans-Regular"
                fontSize="16px"
                fontWeight="600"
                minWidth="80px"
              >
                MAIN COURSE
              </Tabs.Trigger>
              <Tabs.Trigger
                value="desserts"
                marginRight="10px"
                backgroundColor={
                  selectedTab === "desserts" ? "black" : "#fceda7"
                }
                color={selectedTab === "desserts" ? "white" : "black"}
                fontFamily="BubblegumSans-Regular"
                fontSize="16px"
                fontWeight="600"
                minWidth="80px"
              >
                DESSERTS
              </Tabs.Trigger>
              <Tabs.Trigger
                value="drinks"
                backgroundColor={
                  selectedTab === "drinks" ? "black" : "#fceda7"
                }
                color={selectedTab === "drinks" ? "white" : "black"}
                fontFamily="BubblegumSans-Regular"
                fontSize="16px"
                fontWeight="600"
                minWidth="80px"
              >
                DRINKS
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="starters">
              {starters.subCategories.map((category, index) => {
                return (
                  <>
                    <Text
                      className="menu-list-subheading"
                      mb={{ base: "10px", md: "20px" }}
                      mt={index ? "30px" : 0}
                      key={index}
                    >
                      {category}
                    </Text>
                    <Stack>
                      <Flex
                        gap={{ base: "20px", md: "30px" }}
                        wrap="wrap"
                        direction={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="center"
                        marginInline="10px"
                      >
                        {starters.items
                          .filter((data) => data.subType === category)
                          .map((data, i) => {
                            return (
                              <MenuCard
                                imgUrl={data?.imgUrl}
                                foodName={data?.foodName}
                                foodPrice={data?.foodPrice}
                              ></MenuCard>
                            );
                          })}
                      </Flex>
                    </Stack>
                  </>
                );
              })}
            </Tabs.Content>
            <Tabs.Content value="mainCourse">
              {mainCourse.subCategories.map((category, index) => {
                return (
                  <>
                    <Text
                      className="menu-list-subheading"
                      mb={{ base: "10px", md: "20px" }}
                      mt={index ? "30px" : 0}
                      key={index}
                    >
                      {category}
                    </Text>
                    <Stack>
                      <Flex
                        gap={{ base: "20px", md: "30px" }}
                        wrap="wrap"
                        direction={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="center"
                        marginInline="10px"
                      >
                        {mainCourse.items
                          .filter((data) => data.subType === category)
                          .map((data, i) => {
                            return (
                              <MenuCard
                                imgUrl={data?.imgUrl}
                                foodName={data?.foodName}
                                foodPrice={data?.foodPrice}
                              ></MenuCard>
                            );
                          })}
                      </Flex>
                    </Stack>
                  </>
                );
              })}
            </Tabs.Content>
            <Tabs.Content value="desserts">
              {desserts.subCategories.map((category, index) => {
                return (
                  <>
                    <Text
                      className="menu-list-subheading"
                      mb={{ base: "10px", md: "20px" }}
                      mt={index ? "30px" : 0}
                      key={index}
                    >
                      {category}
                    </Text>
                    <Stack>
                      <Flex
                        gap={{ base: "20px", md: "30px" }}
                        wrap="wrap"
                        direction={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="center"
                        marginInline="10px"
                      >
                        {desserts.items
                          .filter((data) => data.subType === category)
                          .map((data, i) => {
                            return (
                              <MenuCard
                                imgUrl={data?.imgUrl}
                                foodName={data?.foodName}
                                foodPrice={data?.foodPrice}
                              ></MenuCard>
                            );
                          })}
                      </Flex>
                    </Stack>
                  </>
                );
              })}
            </Tabs.Content>
            <Tabs.Content value="drinks">
              {drinks.subCategories.map((category, index) => {
                return (
                  <>
                    <Text
                      className="menu-list-subheading"
                      mb={{ base: "10px", md: "20px" }}
                      mt={index ? "30px" : 0}
                      key={index}
                    >
                      {category}
                    </Text>
                    <Stack>
                      <Flex
                        gap={{ base: "20px", md: "30px" }}
                        wrap="wrap"
                        direction={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="center"
                        marginInline="10px"
                      >
                        {drinks.items
                          .filter((data) => data.subType === category)
                          .map((data, i) => {
                            return (
                              <MenuCard
                                imgUrl={data?.imgUrl}
                                foodName={data?.foodName}
                                foodPrice={data?.foodPrice}
                              ></MenuCard>
                            );
                          })}
                      </Flex>
                    </Stack>
                  </>
                );
              })}
            </Tabs.Content>
          </Tabs.Root>
        )}
        {searchValue && (
          <Stack>
            <Flex
              gap={{ base: "20px", md: "30px" }}
              wrap="wrap"
              direction={{ base: "column", md: "row" }}
              justifyContent="center"
              alignItems="center"
              marginInline="10px"
              paddingInline={{ base: "12px", md: "50px" }}
            >
              {filteredMenu.map((data, i) => {
                return (
                  <MenuCard
                    imgUrl={data?.imgUrl}
                    foodName={data?.foodName}
                    foodPrice={data?.foodPrice}
                  ></MenuCard>
                );
              })}
            </Flex>
          </Stack>
        )}
      </Box>
    </>
  );
}
