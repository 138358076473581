const mainCourse = {
  subCategories: [
    "Black Tea",
    "Hot Chocolate",
    "Ice Tea",
    "Ice Coffee",
    "Coffee",
    "Mojitos 500 ml",
    "Virjin Drinks",
    "Healthy Virjin Juice",
    "Ginza Gyunyu Shakes",
    "Dry Fruit Shakes",
    "Fruit Shakes",
    "Soda",
  ],
  items: [
    {
      imgUrl: "images/FoodItems/drinks/BlackTea.jpg",
      foodName: "Black Tea",
      foodPrice: "12",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/BlackGingerTea.jpg",
      foodName: "Black Ginger Tea",
      foodPrice: "20",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Black Lemon Tea",
      foodPrice: "20",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/GingerLemonTea.jpg",
      foodName: "Black Ginger Lemon Tea",
      foodPrice: "25",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/GreenTea.jpg",
      foodName: "Green Tea",
      foodPrice: "25",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/HerbalTea.jpg",
      foodName: "Herbal Tea",
      foodPrice: "20",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/HibiscusTea.jpg",
      foodName: "Hibiscus Tea",
      foodPrice: "25",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/GingerMilkTea.jpg",
      foodName: "Ginger Tea",
      foodPrice: "25",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/MasalaTea.jpg",
      foodName: "Masala Tea",
      foodPrice: "30",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/LemonTea.jpg",
      foodName: "Lemon Tea",
      foodPrice: "20",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/CardamomTea.jpg",
      foodName: "Cardamom Tea",
      foodPrice: "20",
      type: "Drinks",
      subType: "Black Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/HotChocolate.jpg",
      foodName: "Hot Chocoloate",
      foodPrice: "69",
      type: "Drinks",
      subType: "Hot Chocolate",
    },
    {
      imgUrl: "images/FoodItems/drinks/IceTea.jpg",
      foodName: "Ice Tea",
      foodPrice: "45",
      type: "Drinks",
      subType: "Ice Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/IceLemonTea.jpg",
      foodName: "Ice Lemon Tea",
      foodPrice: "45",
      type: "Drinks",
      subType: "Ice Tea",
    },
    {
      imgUrl: "images/FoodItems/drinks/ColdCoffe.jpg",
      foodName: "Cold Coffee",
      foodPrice: "90",
      type: "Drinks",
      subType: "Ice Coffee",
    },
    {
      imgUrl: "images/FoodItems/drinks/IceBlackCoffee.jpeg",
      foodName: "Ice Black Coffee",
      foodPrice: "45",
      type: "Drinks",
      subType: "Ice Coffee",
    },
    {
      imgUrl: "images/FoodItems/drinks/BlackCoffee.jpg",
      foodName: "Black Coffee",
      foodPrice: "20",
      type: "Drinks",
      subType: "Coffee",
    },
    {
      imgUrl: "images/FoodItems/drinks/NormalCoffee.jpg",
      foodName: "Normal Coffee",
      foodPrice: "25",
      type: "Drinks",
      subType: "Coffee",
    },
    {
      imgUrl: "images/FoodItems/drinks/FilterCoffee.jpg",
      foodName: "Filter Coffee",
      foodPrice: "45",
      type: "Drinks",
      subType: "Coffee",
    },
    {
      imgUrl: "images/FoodItems/drinks/RedWineMojito.jpg",
      foodName: "Red wine Mojito",
      foodPrice: "90",
      type: "Drinks",
      subType: "Mojitos 500 ml",
    },
    {
      imgUrl: "images/FoodItems/drinks/RaspberryMojito.jpg",
      foodName: "Raspberry Mojito",
      foodPrice: "90",
      type: "Drinks",
      subType: "Mojitos 500 ml",
    },
    {
      imgUrl: "images/FoodItems/drinks/Blue_curacao.JPG",
      foodName: "Blue Curaco Mojito",
      foodPrice: "90",
      type: "Drinks",
      subType: "Mojitos 500 ml",
    },
    {
      imgUrl: "images/FoodItems/drinks/GreenAppleMojito.jpg",
      foodName: "Green Apple Mojito",
      foodPrice: "90",
      type: "Drinks",
      subType: "Mojitos 500 ml",
    },
    {
      imgUrl: "images/FoodItems/drinks/MintMojito.jpg",
      foodName: "Mint Mojito",
      foodPrice: "90",
      type: "Drinks",
      subType: "Mojitos 500 ml",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Apple Drink",
      foodPrice: "99",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/WatermelonJuice.jpg",
      foodName: "Water Melon Drink",
      foodPrice: "90",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/OrangeDrink.jpg",
      foodName: "Orange Drink",
      foodPrice: "90",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/PineappleDrink.jpg",
      foodName: "Pine Apple Drink",
      foodPrice: "90",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/PomoDrink.jpg",
      foodName: "Pomegranate Drink",
      foodPrice: "99",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/StrawberryJuice.jpg",
      foodName: "Strawberry Drink",
      foodPrice: "129",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/MangoJuice.jpg",
      foodName: "Mango Drink",
      foodPrice: "99",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/GrapeJuice.jpg",
      foodName: "Grapes Drink",
      foodPrice: "90",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/LemonJuice.jpg",
      foodName: "Lemon Drink",
      foodPrice: "59",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/MintLime.jpg",
      foodName: "Mint Lemon Drink",
      foodPrice: "59",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/AmlaDrink.jpg",
      foodName: "Amla Drink",
      foodPrice: "79",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/CarrotJuice.jpg",
      foodName: "Carrot Drink",
      foodPrice: "79",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/MosambiJuice.jpg",
      foodName: "Mosambi Drink",
      foodPrice: "99",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/PapayaDrink.jpg",
      foodName: "Papaya Drink",
      foodPrice: "79",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/GreenAppleDrink.jpg",
      foodName: "Green Apple Drink",
      foodPrice: "129",
      type: "Drinks",
      subType: "Virjin Drinks",
    },
    {
      imgUrl: "images/FoodItems/drinks/BeetrootCarrotAppleJuice.jpg",
      foodName: "Apple / Beetroot / Carrot / Amla Ginza Juice",
      foodPrice: "129",
      type: "Drinks",
      subType: "Healthy Virjin Juice",
    },
    {
      imgUrl: "images/FoodItems/drinks/LemonMintPineappleJuice.jpg",
      foodName: "Lemon / Mint / Pine Apple Juice",
      foodPrice: "99",
      type: "Drinks",
      subType: "Healthy Virjin Juice",
    },
    {
      imgUrl: "images/FoodItems/drinks/AppleCarrotPineapple.jpg",
      foodName: "Apple / Carrot / Pine Apple Juice",
      foodPrice: "99",
      type: "Drinks",
      subType: "Healthy Virjin Juice",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Pista Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl: "images/FoodItems/drinks/BubbleTea.jpg",
      foodName: "Bubble Tea",
      foodPrice: "99",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Chocolate Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Vanilla Shake",
      foodPrice: "90",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Butter Scotch Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Alphonso Mango Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl: "images/FoodItems/drinks/StrawberryMilkshake.jpg",
      foodName: "Strawberry Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Banana Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Dates Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Classic Coffee Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Classic Boost Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Classic Horlicks Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Dry Fruits Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Pine Apple Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl: "images/FoodItems/drinks/Badam_milkshake.JPG",
      foodName: "Badam Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Ginza Gyunyu Shakes",
    },
    {
      imgUrl: "images/FoodItems/drinks/Badam_milkshake.JPG",
      foodName: "Badam Dry Fruit Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Dry Fruit Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mixed Dry Fruit Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Dry Fruit Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Dates Dry Fruit Shake",
      foodPrice: "TBD",
      type: "Drinks",
      subType: "Dry Fruit Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Pine Apple Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Fruit Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mango Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Fruit Shakes",
    },
    {
      imgUrl: "images/FoodItems/drinks/StrawberryMilkshake.jpg",
      foodName: "Strawberry Shake",
      foodPrice: "129",
      type: "Drinks",
      subType: "Fruit Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Muskmelon Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Fruit Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mixed Fruit Shake",
      foodPrice: "129",
      type: "Drinks",
      subType: "Fruit Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Papaya Shake",
      foodPrice: "99",
      type: "Drinks",
      subType: "Fruit Shakes",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Ginger Lemon Soda",
      foodPrice: "69",
      type: "Drinks",
      subType: "Soda",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Lemon Soda",
      foodPrice: "59",
      type: "Drinks",
      subType: "Soda",
    },
    {
      imgUrl:
        "https://mt8848cafe.com/wp-content/uploads/2021/12/food-placeholder.jpg",
      foodName: "Mint Soda",
      foodPrice: "69",
      type: "Drinks",
      subType: "Soda",
    },
  ],
};

export default mainCourse;
